/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////

import { countryCodes } from "../translations/countryCodes";
import { getBrandProcessCash, getDefaultProcessAlias } from '../transactions/transactionHelpers';

// NOTE: if you want to change the structure of the data,
// you should also check src/util/configHelpers.js
// some validation is added there.

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - includeForListingTypes:        An array of listing types, for which the extended
 *   (optional)                     data is relevant and should be added.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  EditListingPage, ListingPage, and SearchPage.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */
export const listingFields = [
    {
        "key": "type",
        "scope": "public",
        "schemaType": "enum",
        "enumOptions": [
            {'label': 'Blazer', 'option': 'blazer'},
            {'label': 'Blusen', 'option': 'blusen'},
            {'label': 'Gürtel', 'option': 'guertel'},
            {'label': 'Handschuhe', 'option': 'handschuhe'},
            {'label': 'Hosen', 'option': 'hosen'},
            {'label': 'Jacken/Mäntel', 'option': 'jackenmaentel'},
            {'label': 'Kleider', 'option': 'kleider'},
            {'label': 'Mützen/Hüte', 'option': 'muetzenhuete'},
            {'label': 'Portemonnaies', 'option': 'portemonnaies'},
            {'label': 'Röcke', 'option': 'roecke'},
            {'label': 'Schmuck', 'option': 'schmuck'},
            {'label': 'Schuhe', 'option': 'schuhe'},
            {'label': 'Shirts', 'option': 'shirts'},
            {'label': 'Strick', 'option': 'strick'},
            {'label': 'Taschen', 'option': 'taschen'},
            {'label': 'Tücher/Schals', 'option': 'tuecherschals'}
        ],
        "filterConfig": {
            "indexForSearch": true,
            "group": "primary",
            "label": "Typ"
        },
        "showConfig": {
            "label": "Typ"
        },
        "saveConfig": {
            "label": "Typ"
        }
    },
    {
        "key": "size",
        "scope": "public",
        "schemaType": "enum",
        "enumOptions": [
            {'label': 'XXS', 'option': 'xxs'},
            {'label': 'XS', 'option': 'xs'},
            {'label': 'S', 'option': 's'},
            {'label': 'S/M', 'option': 's/m'},
            {'label': 'M', 'option': 'm'},
            {'label': 'L', 'option': 'l'},
            {'label': 'XL', 'option': 'xl'},
            {'label': '2XL', 'option': '2xl'},
            {'label': '3XL', 'option': '3xl'},
            {'label': 'XXL', 'option': 'xxl'},
            {'label': '32', 'option': '32'},
            {'label': '34', 'option': '34'},
            {'label': '36', 'option': '36'},
            {'label': '37', 'option': '37'},
            {'label': '38', 'option': '38'},
            {'label': '39', 'option': '39'},
            {'label': '40', 'option': '40'},
            {'label': '40/42', 'option': '40/42'},
            {'label': '41', 'option': '41'},
            {'label': '42', 'option': '42'},
            {'label': '44', 'option': '44'},
            {'label': '46', 'option': '46'},
            {'label': '48', 'option': '48'},
            {'label': '50', 'option': '50'},
            {'label': '52', 'option': '52'},
            {'label': '54', 'option': '54'},
            {'label': '56', 'option': '56'},
            {'label': '75', 'option': '75'},
            {'label': '80', 'option': '80'},
            {'label': '85', 'option': '85'},
            {'label': '90', 'option': '90'},
            {'label': '95', 'option': '95'},
            {'label': '100', 'option': '100'},
            {'label': '105', 'option': '105'},
            {'label': '110', 'option': '110'},
            {'label': '4,5', 'option': '4,5'},
            {'label': '5', 'option': '5'},
            {'label': '5,5', 'option': '5,5'},
            {'label': '6', 'option': '6'},
            {'label': '6,5', 'option': '6,5'},
            {'label': '7', 'option': '7'},
            {'label': '34R', 'option': '34r'},
            {'label': '36R', 'option': '36r'},
            {'label': '38R', 'option': '38r'},
            {'label': '40R', 'option': '40r'},
            {'label': '42R', 'option': '42r'},
            {'label': '44R', 'option': '44r'},
            {'label': '46R', 'option': '46r'},
            {'label': '48R', 'option': '48r'},
            {'label': '34S', 'option': '34s'},
            {'label': '36S', 'option': '36s'},
            {'label': '38S', 'option': '38s'},
            {'label': '40S', 'option': '40s'},
            {'label': '42S', 'option': '42s'},
            {'label': '44S', 'option': '44s'},
            {'label': '46S', 'option': '46s'},
            {'label': '48S', 'option': '48s'},
            {'label': '50S', 'option': '50s'},
            {'label': '52S', 'option': '52s'},
            {'label': '54S', 'option': '54s'},
            {'label': '34L', 'option': '34l'},
            {'label': '36L', 'option': '36l'},
            {'label': '38L', 'option': '38l'},
            {'label': '40L', 'option': '40l'},
            {'label': '42L', 'option': '42l'},
            {'label': '44L', 'option': '44l'},
            {'label': '46L', 'option': '46l'},
            {'label': '48L', 'option': '48l'},
            {'label': '99', 'option': '99'}
        ],
        "filterConfig": {
            "indexForSearch": true,
            "group": "primary",
            "label": "Größe"
        },
        "showConfig": {
            "label": "Größe"
        },
        "saveConfig": {
            "isRequired": true,
            "label": "Größe"
        }
    },
    {
        "key": "condition",
        "scope": "public",
        "schemaType": "enum",
        "enumOptions": [
            {
                "label": "Neu mit Etikett",
                "option": "new_with_tags"
            },
            {
                "label": "Wie neu",
                "option": "like_new"
            },
            {
                "label": "Großartig",
                "option": "great"
            },
            {
                "label": "Gut",
                "option": "good"
            }
        ],
        "filterConfig": {
            "indexForSearch": true,
            "group": "primary",
            "label": "Zustand"
        },
        "showConfig": {
            "label": "Zustand"
        },
        "saveConfig": {
            "isRequired": true,
            "label": "Zustand"
        }
    },
    {
      "key": "country",
      "scope": "public",
      "schemaType": "enum",
      "enumOptions": countryCodes.map(countryCode => ({
        "label": countryCode.de,
        "option": countryCode.en,
      })),
      "filterConfig": {
        "indexForSearch": true,
        "group": "primary",
        "label": "Standort",
      },
      "showConfig": {
        "label": "Standort",
      },
      "saveConfig": {
        "label": "Standort",
      },
    },
    {
        "key": "brandChild",
        "scope": "public",
        "schemaType": "enum",
        "enumOptions": [
            {'label': 'GERRY WEBER', 'option': 'gerryweber'},
            {'label': 'Samoon', 'option': 'samoon'},
            {'label': 'Taifun', 'option': 'taifun'},
            // {'label': 'GERRY WEBER', 'option': 'gerry_weber'}
        ],
        "filterConfig": {
            "indexForSearch": true,
            "group": "primary",
            "label": "Marke"
        },
        "showConfig": {
            "label": "Marke"
        },
        "saveConfig": {
            "isRequired": true,
            "label": "Marke"
        }
    },
    {
        "key": "season",
        "scope": "public",
        "schemaType": "enum",
        "enumOptions": [
            {'label': 'Winter 2019', 'option': 'Winter 2019'},
            {'label': 'Sommer 2019', 'option': 'Summer 2019'},
            {'label': 'Winter 2020', 'option': 'Winter 2020'},
            {'label': 'Sommer 2020', 'option': 'Summer 2020'},
            {'label': 'Winter 2021', 'option': 'Winter 2021'},
            {'label': 'Sommer 2021', 'option': 'Summer 2021'},
            {'label': 'Winter 2022', 'option': 'Winter 2022'},
            {'label': 'Sommer 2022', 'option': 'Summer 2022'},
            {'label': 'Winter 2023', 'option': 'Winter 2023'},
            {'label': 'Sommer 2023', 'option': 'Summer 2023'},
            {'label': 'Sommer 2024', 'option': 'Summer 2024'}
        ],
        "filterConfig": {
            "indexForSearch": true,
            "group": "primary",
            "label": "Saison"
        },
        "showConfig": {
            "label": "Saison"
        },
        "saveConfig": {
            "isRequired": true,
            "label": "Saison"
        }
    }
];

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: With first iteration of hosted configs, we are unlikely to support
//         multiple listing types, even though this template has some
//         rudimentary support for it.
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:         Unique string. This will be saved to listing's public data on
 *                        EditListingWizard.
 * - label                Label for the listing type. Used as microcopy for options to select
 *                        listing type in EditListingWizard.
 * - transactionType      Set of configurations how this listing type will behave when transaction is
 *                        created.
 *   - process              Transaction process.
 *                          The process must match one of the processes that this client app can handle
 *                          (check src/util/transactions/transaction.js) and the process must also exists in correct
 *                          marketplace environment.
 *   - alias                Valid alias for the aforementioned process. This will be saved to listing's
 *                          public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType             Unit type is mainly used as pricing unit. This will be saved to
 *                          transaction's protected data.
 *                          Recommendation: don't use same unit types in completely different processes
 *                          ('item' sold should not be priced the same as 'item' booked).
 * - stockType            This is relevant only to listings using default-purchase process.
 *                        If set to 'oneItem', stock management is not showed and the listing is
 *                        considered unique (stock = 1).
 *                        Possible values: 'oneItem' and 'multipleItems'.
 *                        Default: 'multipleItems'.
 * - defaultListingFields This is relevant only to listings using default-inquiry process atm.
 *                        It contains price: true/false value to indicate, whether price should be shown.
 *                        If defaultListingFields.price is not explicitly set to _false_, price will be shown.
 */
const cashBrandTransactionProcess = getBrandProcessCash();
const alias = getDefaultProcessAlias();

export const listingTypes = [
  {
    listingType: 'product',
    label: 'Purchase cash',
    transactionType: {
      process: cashBrandTransactionProcess,
      alias:  `${cashBrandTransactionProcess}/${alias}`,
      unitType: 'item',
    },
    stockType: 'oneItem',
    defaultListingFields: {
      price: true,
    },
  },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;
