
export const conditionOptions = [
    { value: 'new_with_tags', label: 'Neu mit Etikett' },
    { value: 'like_new', label: 'Wie neu' },
    { value: 'great', label: 'Großartig' },
    { value: 'good', label: 'Gut' },
  ];
  
  export const countryOptions = [
    { value: 'Germany', label: 'Deutschland' },
    { value: 'Austria', label: 'Österreich' },
    { value: 'Netherlands', label: 'Niederlande' },
    { value: 'Belgium', label: 'Belgien' },
    { value: 'France', label: 'Frankreich' },
  ];
  
  export const countryCodeMap = {
    Germany: 'DE',
    Austria: 'AT',
    Netherlands: 'NL',
    Belgium: 'BE',
    France: 'FR',
  };
  